<template>
    <div class="login-wrap">
        <div class="ms-login">
            <div class="ms-title">TEM接触点效益智能管理系统</div>
            <div class="ms-subtitle">密码修改</div>
            <el-form ref="findPasswordForm" :rules="findPasswordRules" :model="findPasswordForm" label-width="0px" class="ms-content">
                <el-form-item prop="oldPswd" label="">
                                <el-input
                                        size="large"
                                        ref="oldPswd"
                                        v-model="findPasswordForm.oldPswd"
                                        placeholder="请输入旧密码"
                                        name="oldPswd"
										type="password"
                                        tabindex="1"
                                        autocomplete="off"
                                        class="form-control"
                                />
                </el-form-item>
                <el-form-item prop="password" label="">
                                <el-input
                                        size="large"
                                        key="password"
                                        ref="password"
                                        v-model="findPasswordForm.password"
                                        type="password"
                                        placeholder="请输入新密码"
                                        name="password"
                                        tabindex="2"
                                        autocomplete="off"
                                />
                            </el-form-item>
                            <el-form-item prop="repeatPassword" label="">
                                <el-input
                                        size="large"
                                        key="repeatPassword"
                                        ref="repeatPassword"
                                        v-model="findPasswordForm.repeatPassword"
                                        type="password"
                                        placeholder="请再次输入新密码"
                                        name="repeatPassword"
                                        tabindex="3"
                                        autocomplete="off"
                                        @keyup.enter.native="submitPassword"
                                />
                            </el-form-item>
                            <div class="l-button">
                                <el-button :loading="loading" type="primary" style="width:100%;margin-bottom:30px;"
                                           class="btn-login"
                                           @click.native.prevent="submitPassword">提交
                                </el-button>
                            </div>
                            <el-button type="text" style="color: #062c87;font-size: 16px;float: right" @click="onLogout">去登录
                            </el-button>

                <!-- <div class="l-end clearfix">
                    <label class="l-check">
                    <input :checked="isSavePassword" @change="onSavePassword" type="checkbox"/><span style="margin-left:10px;">记住密码</span>
                    </label>
                </div>
                <div class="login-btn">
                    <el-button type="primary" @click="submitForm()">登录</el-button>
                </div> -->
            </el-form>
        </div>
    </div>




    <!-- <div class="find-password-container">
        <div style="width:600px;">
        <el-form ref="findPasswordForm" :rules="findPasswordRules" :model="findPasswordForm" class="login-form"
                 autocomplete="on"
                 label-position="left">
            <div class="login-wrap">
                <div class="login-main">
                    <div class="login-header">
                        <div class="login-logo" style="height: 100px; width: 200px;"><img src="../assets/img/logo.png" alt=""></div>
                        <div class="l-title">TEM接触点效益智能管理系统</div>
                    </div>
                    <div class="login-cell">
                        <div class="login-inner">
                            <el-form-item prop="oldPswd" label="旧密码">
                                <el-input
                                        size="large"
                                        ref="oldPswd"
                                        v-model="findPasswordForm.oldPswd"
                                        placeholder="请输入旧密码"
                                        name="oldPswd"
										type="password"
                                        tabindex="1"
                                        autocomplete="off"
                                        class="form-control"
                                />
                            </el-form-item>
                            <el-form-item prop="password" label="新密码">
                                <el-input
                                        size="large"
                                        key="password"
                                        ref="password"
                                        v-model="findPasswordForm.password"
                                        type="password"
                                        placeholder="请输入重置密码"
                                        name="password"
                                        tabindex="2"
                                        autocomplete="off"
                                />
                            </el-form-item>
                            <el-form-item prop="repeatPassword" label="重复密码">
                                <el-input
                                        size="large"
                                        key="repeatPassword"
                                        ref="repeatPassword"
                                        v-model="findPasswordForm.repeatPassword"
                                        type="password"
                                        placeholder="请再次输入密码"
                                        name="repeatPassword"
                                        tabindex="3"
                                        autocomplete="off"
                                        @keyup.enter.native="submitPassword"
                                />
                            </el-form-item>
                            <div class="l-button">
                                <el-button :loading="loading" type="primary" style="width:100%;margin-bottom:30px;"
                                           class="btn-login"
                                           @click.native.prevent="submitPassword">提交
                                </el-button>
                            </div>
                            <el-button type="text" style="color: #062c87;font-size: 16px;float: right" @click="onLogout">去登录
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
        </el-form>
        </div>
    </div> -->
</template>

<script>
import {getToken, removeToken,removeRoleToken,removeSecurityToken} from "@/utils/auth";
import {resetPasswordByOldPassword} from "@/api/user";

export default {
        name: "ResetPassword",
        data() {
            // const validateUsername = (rule, value, callback) => {
            //     console.log(value);
            //     if (value.trim().length > 20) {
            //         callback(new Error('用户名最多20个字符'));
            //     } else if (!value.trim()) {
            //         callback(new Error('请输入注册邮箱'));
            //     } else {
            //         callback();
            //     }
            // }
            const validatePassword = (rule, value, callback) => {
                if (value.trim().length < 6) {
                    callback(new Error('请输入至少6位密码'));
                } else {
                    callback();
                }
            }
            const validateRepeatPassword = (rule, value, callback) => {
                if (value !== this.findPasswordForm.password) {
                    callback(new Error('两次密码不一致'))
                } else {
                    callback()
                }
            }
            return {
                findPasswordForm: {
					oldPswd: '',
                    password: '',
                    repeatPassword: ''
                },
                findPasswordRules: {
					oldPswd: [{required: true, trigger: 'blur', validator: validatePassword}],
                    password: [{required: true,trigger: 'blur', validator: validatePassword}],
                    repeatPassword: [{required: true, trigger: 'blur', validator: validateRepeatPassword}],
                },
                loading: false
            }
        },
        methods: {
            submitPassword() {
                this.$refs.findPasswordForm.validate(async valid => {
                    if (valid) {
                        try {
                            this.loading = true;
                            const {userId} = this.$store.state.user;
                            await resetPasswordByOldPassword({userId: userId || getToken(),oldPswd:this.findPasswordForm.oldPswd,newPswd:this.findPasswordForm.password})
                            removeToken();
							removeRoleToken();
							removeSecurityToken();
                            await this.$router.replace('/login');
                        } catch (e) {
                            console.error(e)
                        } finally {
                            this.loading = false;
                        }
                    } else {
                        console.log('error submit!!')
                        return false
                    }
                })
            },
						async onLogout() {
								removeToken();
								await this.$router.replace('/login');
						}
        }
    }
</script>

<style  scoped>
  .login-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(../assets/img/bg1.jpg);
    background-size: 100%;
}
.ms-title {
    width: 100%;
    line-height: 50px;
    text-align: center;
    font-size: 40px;
    margin-top:20px;
    /* color: #fff; */
    /* border-bottom: 1px solid #ddd; */
}
.ms-subtitle {
    width: 100%;
    line-height: 30px;
    text-align: center;
    font-size: 20px;
    /* color: #fff; */
    margin-top:20px;
    border-bottom: 1px solid #ddd;
}
.ms-login {
    position: absolute;
    left: 45%;
    top: 50%;
    width: 550px;    
    margin: -190px 0 0 -175px;
    border-radius: 5px;
    /* background: rgba(255, 255, 255, 0.3); */
    background-color: white;
    overflow: hidden;
}
.ms-content {
    padding: 30px 30px;
    margin-top:10px;
}
.login-btn {
    text-align: center;
    margin-top:20px;
}
.login-btn button {
    width: 100%;
    height: 36px;
    margin-bottom: 10px;
}
.login-tips {
    font-size: 12px;
    line-height: 30px;
    color: #fff;
}

.background1{
        background: url("../assets/img/zuozhan.jpg") no-repeat;
        background-position: center;
        height: 100%;
        width: 100%;
        background-size: cover;
        position: fixed;
    }

    .background2{
        background: url("../assets/img/zhihui.jpg") no-repeat;
        background-position: center;
        height: 100%;
        width: 100%;
        background-size: cover;
        position: fixed;
    }

    .background3{
        background: url("../assets/img/danyao.jpg") no-repeat;
        background-position: center;
        height: 100%;
        width: 100%;
        background-size: cover;
        position: fixed;
    }

</style>
